import { useEffect, useContext } from 'react';

import pkg from '../../../package.json';
import { AppContext } from '../../contexts/AppContext';
import LocalizationContext from '../../contexts/LocalizationContext';
import Stack from '../../components/Stack';

import routes from '../../absolutes/routes';

function ApplicationSettings() {

    const { setHeader } = useContext(AppContext);
    const { t } = useContext(LocalizationContext);

    const properties = [
        {
            name: t('version'),
            value: pkg.version,
        },
    ];

    useEffect(() => {
        setHeader({
            title: t('application'),
            returnPage: routes.settings,
         });
    }, [setHeader, t]);

    return (
        <Stack>
            <section className="properties">
                {properties.map(({ name, value }, index) => (
                    <div className="property" key={`property_${index}`}>
                        <span className="property__name">{name}</span>
                        <span className="property__value">{value}</span>
                    </div>
                ))}
            </section>
            <section className="section--changelog">
                <h2>Version 1.2.2 [2025-03-02]</h2>
                <h3>Bugfixes</h3>
                <ul>
                    <li className="text--smaller">Show news after login.</li>
                </ul>
                <h2>Version 1.2.1 [2023-07-22]</h2>
                <h3>Bugfixes</h3>
                <ul>
                    <li className="text--smaller">Handle errors on login/signin.</li>
                </ul>
                <h2>Version 1.2.0 [2022-07-12]</h2>
                <h3>Features</h3>
                <ul>
                    <li className="text--smaller">Show most recent news on home screen.</li>
                </ul>
                <h3>Changes</h3>
                <ul>
                    <li className="text--smaller">Use brand yellow color as background, remove Zell tours section and tiles color.</li>
                    <li className="text--smaller">Add application settings/info screen, show version.</li>
                    <li className="text--smaller">Show full URL of website on settings screen.</li>
                </ul>
                <h3>Bugfixes</h3>
                <ul>
                    <li className="text--smaller">Fix german translation of 'member since' in account settings.</li>
                </ul>
            </section>
        </Stack>
    )
}

export default ApplicationSettings;
